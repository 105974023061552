import React, { useEffect } from "react"
import { GlobalStyles } from "../constants/global-styles"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ThemeProvider } from "styled-components"
import HeroContainerTrattamenti from "../containers/trattamenti/hero"

import ReviewsContainer from "../containers/home/reviews"

import CardsContainer from "../containers/home/cards"
import "../constants/global.css"

import PreferencesContainer from "../containers/trattamenti/preferences"

import { useDispatch, useSelector } from "react-redux"
import {
  fetchMedAsync,
  selectBannerMedicina,
  selectMedChirurghi,
  selectMedLoading,
  selectMedPrefs,
  selectMedReviews,
  selectMedSearchItems,
} from "../redux/medicinaSlice"
import LoadingView from "../components/LoadingView/LoadingView"

const theme = {
  main: "#264A79",
  lightblue: "#5492E3",
  gray: "#797979",
}

const MedicinaEsteticaPage = () => {
  const dispatch = useDispatch()
  const reviews = useSelector(selectMedReviews)
  const chirurghi = useSelector(selectMedChirurghi)
  const prefs = useSelector(selectMedPrefs)
  const searchItems = useSelector(selectMedSearchItems)
  const banner = useSelector(selectBannerMedicina)
  const loading = useSelector(selectMedLoading)
  useEffect(() => {
    dispatch(fetchMedAsync())
  }, [])

  if (loading) {
    return <LoadingView />
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Layout>
        <SEO title="Trattamenti" />
        <HeroContainerTrattamenti
          banner={banner}
          title={"chirurgia"}
          searchItems={searchItems}
        />
        <PreferencesContainer Prefs={prefs} />
        <CardsContainer doctors={chirurghi} />
        <ReviewsContainer reviews={reviews} style={{ paddingBottom: 80 }} />
      </Layout>
    </ThemeProvider>
  )
}

export default MedicinaEsteticaPage
